





















































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import ErrorAlert from '@/components/ErrorAlert.vue';
import DatePicker from '@/components/form/DatePicker.vue';
import { CREATE_START_ASSETS_REGISTER, FETCH_START_ASSETS_REGISTER } from '../../../store';
import { ApiResponse } from '@/components/types';
import { CreateStartAssetsRegister, ListOfAssets } from '../../../types';

const ListOfAssets = namespace('listOfAssets');

@Component({
  components: {
    ErrorAlert,
    DatePicker
  }
})
export default class StartAssetDialog extends Vue {
  @Prop({ type: Object }) listOfAssets!: ListOfAssets;

  @ListOfAssets.Action(CREATE_START_ASSETS_REGISTER) createStartAssetsRegister!: (params: CreateStartAssetsRegister) => Promise<ApiResponse>;
  @ListOfAssets.Action(FETCH_START_ASSETS_REGISTER) fetchStartAssetsRegister!: (listOfAssetsId: string) => Promise<ApiResponse>;

  error: any = null;
  dialog: boolean = false;
  loading: boolean = false;

  value: CreateStartAssetsRegister = {
    list_of_assets_id: this.listOfAssets.id,
    start: this.listOfAssets.opened,
    with_receiver: true,
    text: '',
  };

  file: string = '';

  get download() {
    return `Anfangsvermögensverzeichnis-${this.value.start}.pdf`;
  }

  @Watch('dialog')
  watchDialogClosed(dialog: boolean) {
    if (dialog === true) {
      this.value.start = this.listOfAssets.opened;
      this.value.text = '';
      return;
    }

    setTimeout(() => {
      if (this.file !== '') {
        URL.revokeObjectURL(this.file);
      }

      this.error = null;
      this.file = '';
      this.loading = false;
      // @ts-ignore
      this.$refs.form.reset();
    }, 200);
  }

  async save() {
    this.error = null;

    // @ts-ignore
    const result = await this.$refs.form.validate();

    if (!result) return;

    this.loading = true;

    const { error } = await this.createStartAssetsRegister(this.value);

    if (error) {
      this.loading = false;
      this.error = error;

      return;
    }

    const { content, error: fetchError } = await this.fetchStartAssetsRegister(this.listOfAssets.id);

    this.loading = false;

    if (content) {
      this.file = URL.createObjectURL(content);
    }

    if (fetchError) {
      this.error = fetchError;
    }
  }
}
